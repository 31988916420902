import api from "./api";

const getAllVendors = () => {
  return api.get("/getAllVendorsBySuperUser");
};
const vendorApproval = (vendor_id) => {
  return api.post("/vendorApproval", { vendor_id });
};
const vendorRejection = (vendor_id) => {
  return api.post("/vendorRejection", { vendor_id });
};
const addVendorConfig = (data) => {
  return api.post("/addVendorConfig", data);
};
const getVendorConfig = () => {
  return api.get("/getVendorConfig");
};
const getOrderByVendorIdAndLOB = (data) => {
  return api.post("/getOrderByVendorIdAndLOB", data);
};
const getVendorConfigByVendorId = (data) => {
  return api.post("/getVendorConfigByVendorId", data);
};
const getLobByVendorId = (data) => {
  return api.post("/getLobByVendorIdForAdmin", data);
};
const getAllCouponLOB = (data) => {
  return api.post("/getAllCouponLOB", data);
};
const getAllSystemSlotsAndTime = (data) => {
  return api.post("/getAllSystemSlotsAndTime", data);
};
const getLOBbyVendorId = (data) => {
  return api.post("/getLOBbyVendorId", data);
};

const addSlotTimingsByVendor = (data) => {
  return api.post("/addSlotTimingsByVendor", data);
};
const getVendorSlotTimings = (data) => api.post("getVendorSlotTimings", data);

const updateStatusOfSlotTiming = (data) =>
  api.post("updateStatusOfSlotTiming", data);

const updateMaxFranchiseeBySuperAdmin = (data) => {
  return api.post("/updateMaxFranchiseeBySuperAdmin", data);
};

const getLOBForSuperUser = () => {
  return api.get("/getLOBForSuperUser");
};

const addLOBBySuperUser = (data) => {
  return api.post("/addLOBBySuperUser", data);
};
const addCategoryBySuperUser = (data) => {
  return api.post("/addCategoryBySuperUserWithAttributes", data);
};
const getCategoryForSuperUser = () => {
  return api.get("/getCategoryForSuperUser");
};

const updateCategoryStatus = (data) => {
  return api.post("/updateCategoryStatus", data);
};

const getAllFranchiseeByVendorId = (vendor_id) => {
  return api.post("/getAllFranchiseeByVendorId", vendor_id);
};

const addDiscountByVendor = (data) => {
  return api.post("/addDiscountByVendor", data);
};
const getAllDiscountOfVendor = () => {
  return api.post("/getAllDiscountByVendor");
};
const updateDiscountByVendor = (data) => {
  return api.post("/updateDiscountByVendor", data);
};
const deleteDiscountByVendor = (data) => {
  return api.post("/deleteDiscountByVendor", data);
};
const updateConfigBySuperAdmin = (data) => {
  return api.post("/updateVendorTransactionConfigBySuperAdmin", data);
};

const getReceivedOrderInfoByVendor = (vendor_id) => {
  return api.post("/getReceivedOrderInfoByVendor", vendor_id);
};

const getOrderItemsInfoByOrderId = (order_id) => {
  return api.post("/getOrderItemsInfoByOrderId", order_id);
};

const deleteVendorConfigBySuperAdmin = (data) => {
  return api.post("/deleteVendorConfigBySuperAdmin", data);
};

const disableServiceBySuperUser = (data) => {
  return api.post("/disableServiceBySuperUser", data);
};

const enableServiceBySuperUser = (data) => {
  return api.post("/enableServiceBySuperUser", data);
};

const deActivateLOBBySuperUser = (data) => {
  return api.post("/deActivateLOBBySuperUser", data);
};

const activateLOBBySuperUser = (data) => {
  return api.post("/activateLOBBySuperUser", data);
};

const getvendorLocationListByVendorId = (data) => {
  return api.post("/getvendorLocationListByVendorId", data);
};

const sentCustomerNotificationByVendor = (data) => {
  return api.post("/sentCustomerNotificationByVendor", data);
};
const vendorOnboarding = (data) => {
  return api.post("/vendorOnboarding", data);
};

const uploadImageSignup = (file) => {
  return api.post("/uploadImageSignup", file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
const insertAndUpdateVendorOnboarding = (data) => {
  return api.post("/insertAndUpdateVendorOnboarding", data);
};

const getVendorLocation = (vendor_user_id) => {
  return api.post("/getVendorLocation", vendor_user_id);
};

const addLOBsForOnboardingVendor = (data) => {
  return api.post("/addLOBsForOnboardingVendor", data);
};
const getCustomerListByVendorRange = (data) => {
  return api.post("/getCustomerListByVendorRange", data);
};
const generateInvoiceBySuperAdmin = (data) => {
  return api.post("/addInvoiceBySuperAdmin", data);
};
const updateTransactionIdByAdmin = (data) => {
  return api.post("/updateTransactionIdByAdmin", data);
};
const getInvoiceTransactionDetailByAdmin = (data) => {
  return api.post("/getInvoiceTransactionDetailByAdmin", data);
};
const getVendorProductDeatails = (data) => {
  return api.post("/getVendorProductDeatails", data);
};

const insertOrderTimeConfigByVendor = (data) => {
  return api.post("insertOrderTimeConfigByVendor", data);
};

const getVendorOrderTimeConfigonLocation = (data) => {
  return api.post("getVendorOrderTimeConfigonLocation", data);
};

const updateOrderTimeConfigStatus = (data) => {
  return api.post("updateOrderTimeConfigStatus", data);
};
const manageDeliverySlots = (data) => {
  return api.post("manageDeliverySlots", data);
};
const getOrderListForVendor = () => {
  return api.get("b2b/order/getOrderListForVendor");
};

const getOrderDetailsByOrderId = (params = {}) => {
  let url = "b2b/order/getOrderDetailsByOrderId";
  const queryString = new URLSearchParams(params).toString();
  if (queryString) {
    url += `?${queryString}`;
  }
  return api.get(url);
};

const getAttributesByCategoryId = (data) => {
  return api.post("/getAttributesByCategoryId", data);
};

const deleteAttributeByCategoryAndAttributeId = (data) => {
  return api.post("/deleteAttributeByCategoryAndAttributeId", data);
};

const updateAttributeNameAndValue = (data) => {
  return api.post("/updateAttributeNameAndValue", data);
};

const addAttributesByCategoryId = (data) => {
  return api.post("/addAttributesByCategoryId", data);
};
const deleteItemDiscount = (data) => {
  return api.post("/deleteItemDiscount", data);
};
const addUserToDepartment = (data) => {
  return api.post("/common/addUserToDepartment", data);
};
const getAllUserMAppedToDeparments = (params) => {
  let url = "/common/getAllUserMAppedToDeparments/";
  const queryString = new URLSearchParams(params).toString();
  if (queryString) {
    url += `?${queryString}`;
  }
  return api.get(url);
};

const getNotificationType = () => {
  return api.post("/getNotificationType");
};

const notificationTypeConfiguration = (postdata) => {
  return api.post("/notificationTypeConfiguration", postdata);
};
const getDeliveryFeesConfig = () => {
  return api.post("/getDeliveryFeesConfig");
};

const vendorWiseDeliveryFeesConfiguration = (postdata) => {
  return api.post("/vendorWiseDeliveryFeesConfiguration", postdata);
};
const manageSystemConfig = (postdata) => {
  return api.post("/manageSystemConfig", postdata);
};
const updateTransactionIdOnPdfGeneration = (postdata) => {
  return api.post("/updateTransactionIdOnPdfGeneration", postdata);
};
const vendorLOBMapping = (postdata) => {
  return api.post("/vendorLOBMapping", postdata);
};
const updateVendorLOBMappingStatus = (postdata) => {
  return api.post("/updateVendorLOBMappingStatus", postdata);
};
const updateSlotTimingByVendor = (postdata) => {
  return api.post("/updateSlotTimingByVendor", postdata);
};
const changeVendorStatus = (postdata) => {
  return api.post("/changeVendorStatus", postdata);
};
const addOfferCoupon = (postdata) => {
  return api.post("/b2c/addOfferCoupon", postdata);
};
const getAllOfferCoupon = () => {
  return api.get("/b2c/getAllOfferCoupon");
};
const updateOfferCoupon = (data) => {
  return api.post("/b2c/updateOfferCoupon", data);
};
const deleteOfferCoupon = (data) => {
  return api.post("/b2c/deleteOfferCoupon", data);
};
const activateOfferCoupon = (data) => {
  return api.post("/b2c/activateOfferCoupon", data);
};
const deleteWorkflowbyWorkflowId = (data) => {
  return api.post("/deleteWorkflowbyWorkflowId", data);
};
const getNotificationTemplate = () => {
  return api.get("/getNotificationTemplate");
};
const createNotificationTemplate = (formData) => {
  return api.post("/createNotificationTemplate", formData);
};
const editNotificationTemplate = (formData) => {
  return api.post("/editNotificationTemplate", formData);
};
const updateNotificationTemplateStatus = (postdata) => {
  return api.post("/updateNotificationTemplateStatus", postdata);
};
const getNotificationTemplateType = () => {
  return api.get("/getNotificationTemplateType");
};
const getNotificationTemplateByType = (postdata) => {
  return api.post(
    "/getNotificationTemplateForSpecificNotificationType",
    postdata
  );
};
const getNotificationRepeationFrequency = () => {
  return api.get("/getNotificationRepeationFrequency");
};

const createNotificationMapping = (formData) => {
  return api.post("/createNotificationMapping", formData);
};
const getNotificationMapping = () => {
  return api.get("/getNotificationMapping");
};
const updateNotificationMappingStatus = (postdata) => {
  return api.post("/updateNotificationMappingStatus", postdata);
};
const createNotificationForSchedule = (postdata) => {
  return api.post("/createNotificationForSchedule", postdata);
};

const deleteNotificationMapping = (postdata) => {
  return api.post("/deleteNotificationMapping", postdata);
};

const getReceivedOrderReportByVendor = (data) => {
  return api.post("/getReceivedOrderReportByVendor", data);
};

const getCategoryByLOBID = (lob_id) => {
  return api.post("/getProductCategoryByLOBID", lob_id);
};
const vendorWorkflowManageUpdate = (data) => {
  return api.post("/vendorWorkflowManageUpdate", data);
}
const getNotificationDeliveryLogs = (data) => {
  return api.post("/getNotificationDeliveryLogs", data);
};

const VendorService = {
  getAllVendors,
  vendorApproval,
  vendorRejection,
  addVendorConfig,
  getVendorConfig,
  getLobByVendorId,
  getLOBbyVendorId,
  getOrderByVendorIdAndLOB,
  getVendorConfigByVendorId,
  getAllSystemSlotsAndTime,
  addSlotTimingsByVendor,
  getVendorSlotTimings,
  updateStatusOfSlotTiming,
  getLOBForSuperUser,
  addLOBBySuperUser,
  updateMaxFranchiseeBySuperAdmin,
  addCategoryBySuperUser,
  getCategoryForSuperUser,
  updateCategoryStatus,
  getAllFranchiseeByVendorId,
  deleteDiscountByVendor,
  updateDiscountByVendor,
  getAllDiscountOfVendor,
  addDiscountByVendor,
  updateConfigBySuperAdmin,
  getReceivedOrderInfoByVendor,
  getOrderItemsInfoByOrderId,
  deleteVendorConfigBySuperAdmin,
  disableServiceBySuperUser,
  enableServiceBySuperUser,
  deActivateLOBBySuperUser,
  activateLOBBySuperUser,
  getvendorLocationListByVendorId,
  sentCustomerNotificationByVendor,
  vendorOnboarding,
  uploadImageSignup,
  insertAndUpdateVendorOnboarding,
  getVendorLocation,
  addLOBsForOnboardingVendor,
  getCustomerListByVendorRange,
  generateInvoiceBySuperAdmin,
  updateTransactionIdByAdmin,
  getInvoiceTransactionDetailByAdmin,
  getVendorProductDeatails,
  insertOrderTimeConfigByVendor,
  getVendorOrderTimeConfigonLocation,
  updateOrderTimeConfigStatus,
  manageDeliverySlots,
  getOrderListForVendor,
  getOrderDetailsByOrderId,
  getAttributesByCategoryId,
  deleteAttributeByCategoryAndAttributeId,
  updateAttributeNameAndValue,
  addAttributesByCategoryId,
  deleteItemDiscount,
  addUserToDepartment,
  getAllUserMAppedToDeparments,
  notificationTypeConfiguration,
  getNotificationType,
  vendorWiseDeliveryFeesConfiguration,
  getDeliveryFeesConfig,
  manageSystemConfig,
  updateTransactionIdOnPdfGeneration,
  vendorLOBMapping,
  updateVendorLOBMappingStatus,
  updateSlotTimingByVendor,
  changeVendorStatus,
  addOfferCoupon,
  getAllOfferCoupon,
  updateOfferCoupon,
  deleteOfferCoupon,
  activateOfferCoupon,
  deleteWorkflowbyWorkflowId,
  getNotificationTemplate,
  createNotificationTemplate,
  editNotificationTemplate,
  updateNotificationTemplateStatus,
  getNotificationTemplateType,
  getNotificationTemplateByType,
  getNotificationRepeationFrequency,
  createNotificationMapping,
  getNotificationMapping,
  updateNotificationMappingStatus,
  createNotificationForSchedule,
  deleteNotificationMapping,
  getReceivedOrderReportByVendor,
  getAllCouponLOB,
  getCategoryByLOBID,
  vendorWorkflowManageUpdate,
  getNotificationDeliveryLogs,
};

export default VendorService;
