import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import VendorService from "../../services/vendor.services";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import DataTable from "../DataTable/DataTable";
import { FUSEmptyList } from "../../FUSComponents/FUSElements/FUSEmptyList/FUSEmptyList";
import DatePicker from "react-datepicker";
import paginationFactory from "react-bootstrap-table2-paginator";

const NotificationDeliveryLogs = () => {
  const [notificationDeliveryLogs, setNotificationDeliveryLogs] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { errorHandler } = requestAndErrorHandler;
  useEffect(() => {
    getNotificationDeliveryLogs();
  }, [selectedDate]);

  const getNotificationDeliveryLogs = async () => {
    await VendorService.getNotificationDeliveryLogs({ date: selectedDate })
      .then((response) => {
        setNotificationDeliveryLogs(response?.data?.data);
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  const columns = [
    {
      dataField: "user_name",
      text: "User Name",
      sort: true,
    },
    {
      dataField: "notification_body",
      text: "Notification Body",
    },
    {
      dataField: "notification_status",
      text: "Notification Status",
    },
    {
      text: "Notification Delivery Status",
      dataField: "notification_delivery_status",
    },
    {
      text: "Scheduled Time",
      dataField: "scheduled_time",
    },
    {
      text: "Delivery Time",
      dataField: "delivery_time",
    },
  ];

  const options = {
    custom: true,
    totalSize: notificationDeliveryLogs?.length,
    sizePerPageList: [
      {
        text: "15",
        value: 15,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "All",
        value: notificationDeliveryLogs?.length,
      },
    ],
  };

  return (
    <>
      <LayoutContainer
        title1="Configuration"
        title3="Notification Delivery Logs"
        right={
          <div className="d-flex justify-content-end align-items-center gap-3">
            <div className="pt-4">
              <p
                style={{
                  fontWeight: "bold",
                  color: "#7966e4",
                  fontSize: "15px",
                }}
              >
                Select Date
              </p>
            </div>
            <div className="mt-1">
              <DatePicker
                className="px-3 py-2 border-input-color rounded rounded-1"
                dateFormat="yyyy-MM-dd"
                selected={selectedDate}
                onChange={(date) => {
                  setSelectedDate(date);
                }}
                placeholderText="Select Date"
              />
            </div>
          </div>
        }
      >
        {notificationDeliveryLogs.length > 0 ? (
          <div className="px-3">
            <DataTable
              id={"notificationTemplateData"}
              data={notificationDeliveryLogs}
              columns={columns}
              pagination={paginationFactory(options)}
            />
          </div>
        ) : (
          <div className="row">
            <div className="col-md-12">
              <div>
                <div className="pb-5 ">
                  <FUSEmptyList
                    title={`No Notification logs Found for ${selectedDate.toDateString()}`}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </LayoutContainer>
    </>
  );
};

export default NotificationDeliveryLogs;
