import React, { Fragment } from "react";
import "../VendorProductWizards/Wizard.css";
import FUSStepper from "../../FUSComponents/FUSStepper/FUSStepper";
import NotificationTypeConfig from "./NotificationTypeConfig";
import NotificationTemplateConfig from "./NotificationTemplateConfig";
import NotificationMapping from "./NotificationMapping";
import SendVendorNotification from "./SendVendorNotification";
import NotificationDeliveryLogs from "./NotificationDeliveryLogs";

const NotificationWizard = () => {
  const pages = [
    {
      tab_name: "Notification Type Config",
      tab_value: "notification_type",
      isActive: true,
      component: NotificationTypeConfig,
      template: Fragment,
      is_permission_enabled: true,
    },
    {
      tab_name: "Notification Template Config ",
      tab_value: "notification_template",
      isActive: true,
      component: NotificationTemplateConfig,
      template: Fragment,
      is_permission_enabled: true,
    },

    {
      tab_name: "Notification Mapping ",
      tab_value: "notification_mapping",
      isActive: true,
      component: NotificationMapping,
      template: Fragment,
      is_permission_enabled: true,
    },
    {
      tab_name: "Send Notification",
      tab_value: "send_notification",
      isActive: true,
      component: SendVendorNotification,
      template: Fragment,
      is_permission_enabled: true,
    },
    {
      tab_name: "Notification Logs",
      tab_value: "notification_logs",
      isActive: true,
      component: NotificationDeliveryLogs,
      template: Fragment,
      is_permission_enabled: true,
    },
  ];

  return <FUSStepper initialSteps={pages} />;
};

export default NotificationWizard;
