/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import VendorService from "../../services/vendor.services";
import { toast } from "react-toastify";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import _ from "lodash";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import "./VendorsApproval.css";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
const VendorsAprroval = () => {
  const [AllVendors, setAllVendors] = useState([]);
  const [approve, setApprove] = useState(false);
  const [editedIndex, setEditedIndex] = useState(null);
  const [editedMaxLocation, setEditedMaxLocation] = useState("");
  const [itemObject, setObject] = useState({});
  const [modalImage, setModalImage] = useState("");
  const [modalTag, setModalTag] = useState();
  const [isReload,setIsReload]=useState(true)

  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });

  const getAllVendors = async (second) => {
    try {
      const result = await VendorService.getAllVendors();
      if (result?.data?.errorCode === 0) {
    setAllVendors(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleVendorApprove = async (itemObj) => {
    try {
      const result = await VendorService.vendorApproval(itemObj.id);
      if (result?.data?.errorCode === 0) {
        setApprove(!approve);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  const handleVendorRejection = async (itemObj) => {
    try {
      const result = await VendorService.vendorRejection(itemObj.id);
      if (result?.data?.errorCode === 0) {
        setApprove(!approve);
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleVendorActivation = async (itemObj) => {
    try {
      const result = await VendorService.changeVendorStatus({
        vendor_id: itemObj.id,
        type: itemObj?.is_active ? "DEACTIVATE" : "ACTIVATE",
      });
      if (result?.data?.errorCode === 0) {
        setApprove(!approve);
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handleEdit = (index, item) => {
    setEditedIndex(index);
    setEditedMaxLocation(item?.maxLocation);
    setObject(item);
  };
  const handleCancel = () => {
    setEditedIndex(null);
    setEditedMaxLocation("");
    setObject({});
  };

  const handleSave = async () => {
    try {
      const result = await VendorService.updateMaxFranchiseeBySuperAdmin({
        max_location: editedMaxLocation,
        vendor_id: itemObject?.id,
      });
      if (result?.data?.errorCode === 0) {
        const successToast = toast.success(`${result?.data?.message}`, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setApprove(!approve);
        setEditedIndex(null);
        setObject({});
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };
  useEffect(() => {
    getAllVendors();
  }, [approve, isReload]);

  const renderLink = (item, tag, keyName) => {
    const imgPic = _.find(item?.doc_images, { tag: tag });
    const hasPic = !!imgPic;

    if (hasPic) {
      const imageName = imgPic?.image_name;
      return (
        <>
        {/* For Further Use */}
          {/* <a href={imgPic.image_name} target="_blank" rel="noopener noreferrer">
            {item[keyName] ? item[keyName].toUpperCase() : item[keyName]}
          </a> */}
          <FUSButton
            className={"view_button_style px-2 py-2"}
            iconSrc="md"
            iconName="MdPreview"
            iconSize={15}
            onClick={() => handleShowModal(imageName, tag)}
          />
        </>
      );
    } else {
      return item.gst_no ? item.gst_no.toUpperCase() : item.gst_no;
    }
  };
  const handleShowModal = (image, tag) => {
    setModalImage(image);
    setModalTag(tag);

    setShowModal((prev) => ({ ...prev, state: true, type: "PANCardImage" }));
  };


  const handleVendorWorkflow=async (item)=>{
  
    await VendorService.vendorWorkflowManageUpdate(
      {vendor_id:item?.id, is_active:!item?.vendor_manage_workflow
      }).then((res)=>{
      
      if(res?.data?.errorCode===0){
         toast.success(`${res?.data?.message}`, {
          position: "bottom-left",
          autoClose: 2000,
        });
      
    setIsReload(isReload=>!isReload)
      }
    }).catch((error)=>{
      requestAndErrorHandler?.errorHandler(error)
    })

  }
  return (
    <>
      <LayoutContainer title1="Vendor" title2="Vendor" title3="Vendor Approval">
        <div className="px-3 py-2">
          <table className="table rounded rounded-2  border border-1 table-hover table-bordered">
            <thead>
              <tr>
                <th>Vendor Id</th>
                <th>Name</th>
                <th>LOB</th>
                <th>Active</th>
                <th>Vendor manages workflow</th>

                <th>Is Admin Approved</th>
                <th>GST No.</th>
                <th>PAN Card</th>
                <th>Aadhar Card</th>
                <th>Address</th>
                <th>City</th>
                <th>Maximum Locations</th>
              </tr>
            </thead>
            <tbody>
              {AllVendors &&
                AllVendors.map((item, index) => (
                  <tr>
                    <td> {item?.id} </td>
                    <td> {item?.vendor_name} </td>
                    <td>
                      {item?.lob &&
                        item?.lob.map((val, index) => (
                          <>
                            <p>{val.lob}</p>
                          </>
                        ))}
                    </td>
                    <td>
                      {" "}
                      {item?.is_active ? (
                        <a
                          href="#"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleVendorActivation(item)}
                        >
                          Activated
                        </a>
                      ) : (
                        <a
                          href="#"
                          style={{ cursor: "pointer" }}
                          className="error-text"
                          onClick={() => handleVendorActivation(item)}
                        >
                          Not Activated
                        </a>
                      )}
                    </td>
                    <td>
                      
                         <a
                          href="#"
                          style={{ cursor: "pointer" }}
                          className={`${item?.vendor_manage_workflow ?  'text-success' :  
                             _.isNil(item?.vendor_manage_workflow) || 
                           !item?.vendor_manage_workflow  ? 'text-danger': 'text-danger'}`}
                          onClick={() => handleVendorWorkflow(item)}
                        >

                           {item?.vendor_manage_workflow ?  'YES' :  
                             _.isNil(item?.vendor_manage_workflow) || 
                           !item?.vendor_manage_workflow  ? 'No ': 'NO'}
                        </a>
                    
                    </td>
                    <td>
                      {item?.is_approved ? (
                        <a
                          href="#"
                          style={{ cursor: "pointer" }}
                          onClick={() => handleVendorRejection(item)}
                        >
                          Yes
                        </a>
                      ) : (
                        <a
                          href="#"
                          style={{ cursor: "pointer" }}
                          className="error-text"
                          onClick={() => handleVendorApprove(item)}
                        >
                          No
                        </a>
                      )}
                    </td>
                    <td>{renderLink(item, "GST_PIC", "gst_no")}</td>
                    <td>{renderLink(item, "PAN_PIC", "pan_card")}</td>
                    <td>{renderLink(item, "AADHAR_PIC", "aadhar_no")}</td>
                    <td> {item?.address} </td>
                    <td> {item?.city} </td>
                    <td class="text-center d-flex align-items-center gap-2">
                      {editedIndex === index ? (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter"
                          value={editedMaxLocation}
                          onChange={(e) => setEditedMaxLocation(e.target.value)}
                        />
                      ) : (
                        item.max_location
                      )}
                      {editedIndex === index ? (
                        <>
                          <FUSButton
                            buttonTextStyle="fs-6"
                            className={"py-1"}
                            labelText={"Save"}
                            buttonType="primary"
                            onClick={handleSave}
                          />

                          <FUSButton
                            buttonTextStyle="fs-6"
                            className={"py-1"}
                            labelText={"Cancel"}
                            buttonType="secondary"
                            onClick={handleCancel}
                          />
                        </>
                      ) : (
                        <FUSButton
                          className={"px-2 py-2"}
                          iconSrc="md"
                          iconName="MdOutlineModeEdit"
                          iconSize={14}
                          buttonType="lightgray"
                          onClick={() => handleEdit(index, item)}
                        />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {showModal.state && (
          <FUSModal
            title={
              modalTag === "PAN_PIC"
                ? "PAN Card - Protecons Agency"
                : "Aadhar Card - Protecons Agency"
            }
            showModal={showModal.state}
            size={"md"}
            handleClose={() =>
              setShowModal((prev) => ({ ...prev, state: false }))
            }
            centered
          >
            {showModal.type === "PANCardImage" && (
              <div className="px-1 py-2">
                <img src={modalImage} className="modal_image" />
              </div>
            )}
          </FUSModal>
        )}
      </LayoutContainer>
    </>
  );
};

export default VendorsAprroval;
