import React, { useEffect, useState } from "react";
import LayoutContainer from "../../FUSComponents/LayoutContainer/LayoutContainer";
import BootstrapTable from "react-bootstrap-table-next";
import { FUSButton } from "../../FUSComponents/FUSElements/FUSButton/FUSButton";
import FUSModal from "../../FUSComponents/FUSModal/FUSModal";
import MstService from "../../services/mst.service";
import requestAndErrorHandler from "../../services/responseAndErrorHandler";
import { toast } from "react-toastify";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import _ from "lodash";
import VendorService from "../../services/vendor.services";

const AppServices = () => {
  const [appServiceList, setAppServiceList] = useState([]);
  const [allLob, setAllLob] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [iconName, setIconName] = useState(null);
  const [iconSrc, setIconSrc] = useState(null);
  const [bgImg, setBgImg] = useState(null);
  const [serviceName, setServiceName] = useState(null);
  const [route, setRoute] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [selectedLob, setSelectedLob] = useState(null);
  const [id, setId] = useState(null);
  const [status, setStatus] = useState(null);
  const [shortName, setShortName] = useState(null);
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState({
    state: false,
    type: "",
  });

  const columns = [
    {
      dataField: "icon_name",
      text: "Icon Name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "icon_src",
      text: "icon src",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "bg_img",
      text: "bg image",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "route",
      text: "Route",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "service_name",
      text: "Service Name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "short_name",
      text: "Short Name",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "order_by",
      text: "Order By",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "is_active",
      text: "Status",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "lob_name",
      text: "LOB",
      sort: true,
      filter: textFilter(),
    },
    {
      dataField: "remove",
      text: "Action",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <FUSButton
                className={"action_button_style"}
                iconSrc="md"
                iconName="MdOutlineModeEdit"
                iconSize={16}
                buttonType="lightgray"
                onClick={() => handleEdit(row)}
              />
              <FUSButton
                className={"action_button_style"}
                iconSrc="md"
                iconName="MdDeleteForever"
                iconSize={15}
                buttonType="lightdanger"
                onClick={() => handleDelete(row.id)}
              />
            </div>
          </>
        );
      },
      editable: false,
    },
  ];

  const handleEdit = (row) => {
    // Populate modal with the values of the selected row
    setIconName(row.icon_name);
    setIconSrc(row.icon_src);
    setBgImg(row.bg_img);
    setServiceName(row.service_name);
    setRoute(row.route);
    setOrderBy(row.order_by);
    setId(row.id);
    setStatus(row.is_active);
    setShortName(row.short_name);

    // Show modal for editing
    setShowModal({
      state: true,
      type: "EDITAPPSERVICES", // Assuming this is the correct modal type
    });
  };

  const handleDelete = async (id) => {
    const isDeleteConfirm = window.confirm("Do you want to delete");
    if (isDeleteConfirm) {
      try {
        const result = await MstService.deleteAppServiceDetailsBySuperAdmin({
          id: id,
        });
        if (result?.data?.errorCode === 0) {
          getAppServiceList();
          const successToast = toast.success(`${result?.data?.message}`, {
            position: "bottom-left",
            autoClose: false,
          });
          setTimeout(() => {
            toast.dismiss(successToast);
          }, 2000);
        }
      } catch (error) {
        requestAndErrorHandler.errorHandler(error);
      }
    }
  };
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  const featureListOption = {
    paginationSize: 4,
    pageStartIndex: 0,

    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "10",
        value: 10,
      },
      {
        text: "15",
        value: 15,
      },
      {
        text: "All",
        value: appServiceList.length,
      },
    ],
  };

  const getAppServiceList = async () => {
    try {
      const res = await MstService.getAppServiceList({
        type: "all",
      });
      setAppServiceList([]);
      if (res?.data?.errorCode === 0) {
        setAppServiceList(res?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const insertAppServiceDetailsBySuperAdmin = async () => {
    try {
      const result = await MstService.insertAppServiceDetailsBySuperAdmin({
        icon_name: iconName,
        icon_src: iconSrc,
        bg_img: bgImg,
        service_name: serviceName,
        route: route,
        order_by: orderBy,
        short_name: shortName,
        lob_id: selectedLob,
      });
      if (result?.data?.errorCode === 0) {
        const successToast = toast.success(result?.data?.message, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
        setIconName(null);
        setIconSrc(null);
        setBgImg(null);
        setServiceName(null);
        setRoute(null);
        setOrderBy(null);
        setStatus(null);
        setId(null);
        setErrors({});
        setShortName(null);
      }
      getAppServiceList();
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const handeSubmit = () => {
    const data = { iconName, iconSrc, orderBy };
    const checkNullEmptyValues = (obj) => {
      const errors = {};
      _.forOwn(obj, (value, key) => {
        if (_.isNil(value) || value === "") {
          errors[key] = `${_.startCase(key)} is required`;
        }
      });
      return errors;
    };
    const dataError = checkNullEmptyValues(data);
    if (_.isEmpty(dataError)) {
      insertAppServiceDetailsBySuperAdmin();
      setShowModal((prev) => ({ ...prev, state: false }));
      setErrors({});
    } else {
      setErrors(dataError);
    }
  };

  const handleUpdate = async () => {
    try {
      const result = await MstService.updateAppServiceDetailsBySuperAdmin({
        icon_name: iconName,
        icon_src: iconSrc,
        bg_img: bgImg,
        service_name: serviceName,
        short_name: shortName,
        route: route,
        order_by: orderBy,
        is_active: status,
        id: id,
        lob_id: selectedLob,
      });
      if (result?.data?.errorCode === 0) {
        const successToast = toast.success(result?.data?.message, {
          position: "bottom-left",
          autoClose: false,
        });
        setTimeout(() => {
          toast.dismiss(successToast);
        }, 2000);
      }
      setShowModal((prev) => ({ ...prev, state: false }));
      getAppServiceList();
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  const getLOBForSuperUser = async () => {
    try {
      const result = await VendorService.getLOBForSuperUser();
      if (result?.data?.errorCode === 0) {
        setAllLob(result?.data?.data);
      }
    } catch (error) {
      requestAndErrorHandler.errorHandler(error);
    }
  };

  useEffect(() => {
    getAppServiceList();
  }, []);

  useEffect(() => {
    getLOBForSuperUser();
  }, [toggle]);

  return (
    <>
      <LayoutContainer
        title1="AppServices"
        title2="AppServices"
        title3="app services"
        right={
          <>
            <FUSButton
              iconSrc={"fa6"}
              iconName={"FaPlus"}
              iconSize={14}
              buttonType="primary"
              labelText={"Add App Services"}
              onClick={() => {
                setShowModal((prev) => ({
                  ...prev,
                  state: true,
                  type: "APPSERVICES",
                }));
              }}
            />
          </>
        }
      >
        <div className="px-3 py-3">
          <div className="col-md-12">
            <BootstrapTable
              classes="border border-1"
              keyField="fcm_key"
              data={appServiceList}
              columns={columns}
              pagination={paginationFactory(featureListOption)}
              filter={filterFactory()}
            />
          </div>
        </div>
        {showModal.state && (
          <FUSModal
            title={
              showModal.type === "APPSERVICES"
                ? "App Services"
                : "Edit App services"
            }
            showModal={showModal.state}
            size={"md"}
            handleClose={() => {
              setShowModal((prev) => ({ ...prev, state: false }));
              setIconName(null);
              setIconSrc(null);
              setBgImg(null);
              setServiceName(null);
              setRoute(null);
              setOrderBy(null);
              setId(null);
              setStatus(null);
              setErrors({});
            }}
            centered
          >
            {showModal.type === "APPSERVICES" && (
              <div className="px-3 col py-3">
                <div className="row">
                  <div className="col-md-6 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>Icon Name</label>
                      <input
                        type="text"
                        name="display_sec_text"
                        className="form-control form-control-sm mt-1"
                        placeholder="Enter Icon Name"
                        onChange={(e) => setIconName(e.target.value)}
                      />
                    </div>
                    {iconName === "" ||
                    iconName === null ||
                    iconName === undefined ? (
                      <span className="error-text">{errors?.iconName}</span>
                    ) : null}
                  </div>
                  <div className="col-md-6 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>Icon src</label>
                      <input
                        type="text"
                        name="display_sec_text"
                        className="form-control form-control-sm mt-1"
                        placeholder="Enter Icon src"
                        onChange={(e) => setIconSrc(e.target.value)}
                      />
                    </div>
                    {iconSrc === "" ||
                    iconSrc === null ||
                    iconSrc === undefined ? (
                      <span className="error-text">{errors?.iconSrc}</span>
                    ) : null}
                  </div>
                  <div className="col-md-12 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>Background Image</label>
                      <input
                        type="text"
                        name="display_sec_text"
                        className="form-control form-control-sm mt-1"
                        placeholder="Enter Background Image"
                        onChange={(e) => setBgImg(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>Service Name</label>
                      <input
                        type="text"
                        name="display_sec_text"
                        className="form-control form-control-sm mt-1"
                        placeholder="Enter Service Name"
                        onChange={(e) => setServiceName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>Short Name</label>
                      <input
                        type="text"
                        name="display_sec_text"
                        className="form-control form-control-sm mt-1"
                        placeholder="Enter Short Name"
                        onChange={(e) => setShortName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>Route</label>
                      <input
                        type="text"
                        name="display_sec_text"
                        className="form-control form-control-sm mt-1"
                        placeholder="Enter route"
                        onChange={(e) => setRoute(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>Order By</label>
                      <input
                        type="number"
                        name="display_sec_text"
                        className="form-control form-control-sm mt-1"
                        placeholder="Enter Order By"
                        onChange={(e) => setOrderBy(e.target.value)}
                      />
                    </div>
                    {orderBy === "" ||
                    orderBy === null ||
                    orderBy === undefined ? (
                      <span className="error-text">{errors?.orderBy}</span>
                    ) : null}
                  </div>
                  <div className="col-md-12 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>LOB </label>
                      <select
                        className="form-select"
                        name=""
                        id=""
                        value={selectedLob}
                        onChange={(e) => setSelectedLob(e.target.value)}
                      >
                        <option value="" selected>
                          Select LOB
                        </option>
                        {allLob?.length > 0 &&
                          allLob.map((item) => {
                            return (
                              <>
                                <option value={item?.id}>{item?.lob}</option>
                              </>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-3 mt-3 px-0">
                  <FUSButton
                    labelText={"Submit"}
                    buttonType="primary"
                    className={"form-control py-2"}
                    onClick={() => handeSubmit()}
                  ></FUSButton>
                </div>
              </div>
            )}
            {showModal.type === "EDITAPPSERVICES" && (
              <div className="px-3 col py-3">
                <div className="row">
                  <div className="col-md-6 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>Icon Name</label>
                      <input
                        type="text"
                        name="display_sec_text"
                        className="form-control form-control-sm mt-1"
                        placeholder="Enter Icon Name"
                        value={iconName}
                        onChange={(e) => setIconName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>Icon src</label>
                      <input
                        type="text"
                        name="display_sec_text"
                        className="form-control form-control-sm mt-1"
                        placeholder="Enter Icon src"
                        value={iconSrc}
                        onChange={(e) => setIconSrc(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>Background Image</label>
                      <input
                        type="text"
                        name="display_sec_text"
                        className="form-control form-control-sm mt-1"
                        placeholder="Enter Background Image"
                        value={bgImg}
                        onChange={(e) => setBgImg(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>Service Name</label>
                      <input
                        type="text"
                        name="display_sec_text"
                        className="form-control form-control-sm mt-1"
                        placeholder="Enter Service Name"
                        value={serviceName}
                        onChange={(e) => setServiceName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>Short Name</label>
                      <input
                        type="text"
                        name="display_sec_text"
                        className="form-control form-control-sm mt-1"
                        placeholder="Enter Short Name"
                        value={shortName}
                        onChange={(e) => setShortName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>Route</label>
                      <input
                        type="text"
                        name="display_sec_text"
                        className="form-control form-control-sm mt-1"
                        placeholder="Enter route"
                        value={route}
                        onChange={(e) => setRoute(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 ps-0 ms-0">
                    <div>status</div>
                    <div className="form-group">
                      <select
                        name="filter"
                        id="filter"
                        className="form-select py-2 text_style"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value={""}>Select</option>
                        <option value={true}>True</option>
                        <option value={false}>False</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>Order By</label>
                      <input
                        type="number"
                        name="display_sec_text"
                        className="form-control form-control-sm mt-1"
                        placeholder="Enter Order By"
                        value={orderBy}
                        onChange={(e) => setOrderBy(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-12 ps-0 ms-0">
                    <div className="form-group mt-3">
                      <label>LOB </label>
                      <select
                        className="form-select"
                        name=""
                        id=""
                        value={selectedLob}
                        onChange={(e) => setSelectedLob(e.target.value)}
                      >
                        <option value="" selected>
                          Select LOB
                        </option>
                        {allLob?.length > 0 &&
                          allLob.map((item) => {
                            return (
                              <>
                                <option value={item?.id}>{item?.lob}</option>
                              </>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-3 mt-3 px-0">
                  <FUSButton
                    labelText={"Update"}
                    buttonType="primary"
                    className={"form-control py-2"}
                    onClick={() => handleUpdate()}
                  ></FUSButton>
                </div>
              </div>
            )}
          </FUSModal>
        )}
      </LayoutContainer>
    </>
  );
};

export default AppServices;
