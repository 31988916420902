const FEATURES = {
  DASHBOARD: "DASHBOARD",
  BEAT_UPLOAD: "BEAT_UPLOAD",
  CASH_MEMO: "CASH_MEMO",
  CONFIGURATION: "CONFIGURATION",
  NOTIFICATION: "NOTIFICATION",
  VENDOR_NOTIFICATION: "VENDOR_NOTIFICATION",
  ROLES_AND_PERMISSIONS: "ROLES_AND_PERMISSIONS",
  REPORTS: "REPORTS",
  TRACKING: "TRACKING",
  WORKFLOW: "WORKFLOW",
  INACTIVE_CLIENTS: "INACTIVE_CLIENTS",
  LEAD: "LEAD",
  EXPENSE: "EXPENSE",
  TERRITORY: "TERRITORY",
  HOLIDAYS: "HOLIDAYS",
  DEPARTMENT: "DEPARTMENT",
  SETTING: "SETTING",
  SETTINGS: "SETTINGS",
  OPEN_PARTY_COLLECTION: "OPEN_PARTY_COLLECTION",
  GODOWN_UPLOAD: "GODOWN_UPLOAD",
  SIFY: "SIFY",
  BANK_DEPOSIT: "BANK_DEPOSIT",
  FEATURE_PRODUCT: "FEATURE_PRODUCT",
  ORDER_MANAGEMENT: "ORDER_MANAGEMENT",
  COLLECTOR_DASHBOARD: "COLLECTOR_DASHBOARD",
  OFFERS: "OFFERS",
  PARTY_MASTER: "PARTY_MASTER",
  DISPATCH_MANAGEMENT: "DISPATCH_MANAGEMENT",
};

const FUNCTIONALITIES = {
  BEAT_FILE_UPLOAD: "BEAT_FILE_UPLOAD",
  BEAT_UPLOAD_COLLECTOR_WISE: "BEAT_UPLOAD_COLLECTOR_WISE",
  ONE_TIME_BEAT_UPLOAD: "ONE_TIME_BEAT_UPLOAD",
  CASH_MEMO: "CASH_MEMO",
  HISTORY_CASH_MEMO: "HISTORY_CASH_MEMO",
  CREATE_COLLECTOR: "CREATE_COLLECTOR",
  CREATE_COLLECTOR_CODE: "CREATE_COLLECTOR_CODE",
  MAPPING: "MAPPING",
  BEAT_FILE_MAPPING: "BEAT_FILE_MAPPING",
  USER_HIERARCHY: "USER_HIERARCHY",
  VIEW_INACTIVE_CLIENTS: "VIEW_INACTIVE_CLIENTS",
  SEND_PUSH_NOTIFICATION: "SEND_PUSH_NOTIFICATION",
  VENDOR_NOTIFICATION_CLIENT: "VENDOR_NOTIFICATION_CLIENT",
  CREATE_ROLE: "CREATE_ROLE",
  ASSIGN_ROLE: "ASSIGN_ROLE",
  COLLECTION_DATA: "COLLECTION_DATA",
  COLLECTION_REPORT: "COLLECTION_REPORT",
  COLLECTION_HISTORY: "COLLECTION_HISTORY",
  EXCEL_UPLOAD_VIEW: "EXCEL_UPLOAD_VIEW",
  COLLECTION_REPORT_BALANCE: "COLLECTION_REPORT_BALANCE",
  COLLECTION_REPORT_HISTORY: "COLLECTION_REPORT_HISTORY",
  TRACKING: "TRACKING",
  CREATE_WORKFLOW: "CREATE_WORKFLOW",
  VIEW_WORKFLOW: "VIEW_WORKFLOW",
  INACTIVE_CLIENTS: "INACTIVE_CLIENTS",
  ONE_TIME_BEAT_ROUTES: "ONE_TIME_BEAT_ROUTES",
  ADD_LEAD_TYPE: "ADD_LEAD_TYPE",
  DEPARTMENT_MAPPING: "DEPARTMENT_MAPPING",
  UPLOAD_GODOWN_COLLECTION: "UPLOAD_GODOWN_COLLECTION",
  EXPENSE_TYPE: "EXPENSE_TYPE",
  EXPENSE_CATEGORY: "EXPENSE_CATEGORY",
  VISIT_TYPE: "VISIT_TYPE",
  HOLIDAYS: "HOLIDAYS",
  DEPARTMENT: "DEPARTMENT",
  ADD_BANK: "ADD_BANK",
  VIEW_TERRITORY: "VIEW_TERRITORY",
  OPEN_PARTY_COLLECTION_REPORT: "OPEN_PARTY_COLLECTION_REPORT",
  GODOWN_COLLECTION_REPORT: "GODOWN_COLLECTION_REPORT",
  FEATURE_BEAT_UPLOAD: "FEATURE_BEAT_UPLOAD",
  TODAY_BEAT_REUPLOAD: "TODAY_BEAT_REUPLOAD",
  COLLECTION_REPORT_INVOICE: "COLLECTION_REPORT_INVOICE",
  VIEW_GODOWN_MASTER: "VIEW_GODOWN_MASTER",
  GODOWN_MASTER_MAPPING: "GODOWN_MASTER_MAPPING",
  MANAGE_PRODUCT: "MANAGE_PRODUCT",
  ADD_PRODUCT: "ADD_PRODUCT",
  SIFY_SALES_DATA: "SIFY_SALES_DATA",
  SIFY_REPORT: "SIFY_REPORT",
  SIFY_MASTER_ITEM: "SIFY_MASTER_ITEM ",
  VIEW_BANK_DEPOSIT: "VIEW_BANK_DEPOSIT",
  GODOWN_HISTORY_CASH_MEMO: "GODOWN_HISTORY_CASH_MEMO",
  OPEN_PARTY_HISTORY_CASH_MEMO: "OPEN_PARTY_HISTORY_CASH_MEMO",
  MARG_REPORT: "MARG_REPORT",
  CASH_FLOW: "CASH_FLOW",
  VIEW_COLLECTOR_DASHBOARD: "VIEW_COLLECTOR_DASHBOARD",
  MANAGE_ORDER: "MANAGE_ORDER",
  ORDER_CONFIG: "ORDER_CONFIG",
  CUTOFF_TIME: "CUTOFF_TIME",
  MANAGE_USERS: "MANAGE_USERS",
  MANAGE_DELIVERY_SLOTS: "MANAGE_DELIVERY_SLOTS",
  MANAGE_HOLIDAY: "MANAGE_HOLIDAY",
  PURCHASING_PLAN: "PURCHASING_PLAN",
  PACKAGING_PLAN: "PACKAGING_PLAN",
  SIFY_PURCHASE_DATA: "SIFY_PURCHASE_DATA",
  SIFY_PURCHASE_REPORT: "SIFY_PURCHASE_REPORT",
  COUPON: "COUPON",
  CONFIGURATION: "CONFIGURATION",
  VENDOR_ADMIN_LMENU_MANAGE_USER: "VENDOR_ADMIN_LMENU_MANAGE_USER",
  VENDOR_ADMIN_BUTTON_MANAGE_USER_ADD: "VENDOR_ADMIN_BUTTON_MANAGE_USER_ADD",
  VENDOR_ADMIN_LMENU_MANAGE_DELIVERY_SLOT:
    "VENDOR_ADMIN_LMENU_MANAGE_DELIVERY_SLOT",
  VENDOR_ADMIN_BUTTON_MANAGE_DELIVERY_SLOT_ADD:
    "VENDOR_ADMIN_BUTTON_MANAGE_DELIVERY_SLOT_ADD",
  VENDOR_ADMIN_BUTTON_MANAGE_DELIVERY_SLOT_DEACTIVE:
    "VENDOR_ADMIN_BUTTON_MANAGE_DELIVERY_SLOT_DEACTIVE",
  VENDOR_ADMIN_LMENU_MANAGE_PRODUCTS: "VENDOR_ADMIN_LMENU_MANAGE_PRODUCTS",
  VENDOR_ADMIN_LMENU_VIEW_ORDERS: "VENDOR_ADMIN_LMENU_VIEW_ORDERS",
  VENDOR_ADMIN_LMENU_ORDER_CONFIG: "VENDOR_ADMIN_LMENU_ORDER_CONFIG",
  VENDOR_ADMIN_LMENU_HOLIDAY_CONFIG: "VENDOR_ADMIN_LMENU_HOLIDAY_CONFIG",
  VENDOR_ADMIN_LMENU_PURCHASE_PLAN: "VENDOR_ADMIN_LMENU_PURCHASE_PLAN",
  VENDOR_ADMIN_LMENU_PACKAGE_PLAN: "VENDOR_ADMIN_LMENU_PACKAGE_PLAN",
  VENDOR_ADMIN_LMENU_VENDOR_COUPAN_CONFIG:
    "VENDOR_ADMIN_LMENU_VENDOR_COUPAN_CONFIG",
  VENDOR_ADMIN_LMENU_VENDOR_ITEMS_DISCOUNT:
    "VENDOR_ADMIN_LMENU_VENDOR_ITEMS_DISCOUNT",
  ROLES_AND_PERMISSIONS: "ROLES_AND_PERMISSIONS",
  BUTTON_ASSIGN_ROLE: "BUTTON_ASSIGN_ROLE",
  WORKFLOW: "WORKFLOW",
  ORDER_MANAGEMENT: "ORDER_MANAGEMENT",
  VENDOR_ADMIN_LMENU_ORDER_TIME_CONFIG: "VENDOR_ADMIN_LMENU_ORDER_TIME_CONFIG",
  VENDOR_LMENU_MANAGE_DEPARTMENT: "VENDOR_LMENU_MANAGE_DEPARTMENT",
  VENDOR_LMENU_MANAGE_CATEGORY: "VENDOR_LMENU_MANAGE_CATEGORY",
  VENDOR_LMENU_PARTY_MASTER: "VENDOR_LMENU_PARTY_MASTER",
  VENDOR_ADMIN_LMENU_MNG_DSHBRD_SECTION:
    "VENDOR_ADMIN_LMENU_MNG_DSHBRD_SECTION",

  VENDOR_ADMIN_LMENU_VIEW_DISPATCH: "VENDOR_ADMIN_LMENU_VIEW_DISPATCH",
  VENDOR_ADMIN_LMENU_MANAGE_SERVICES: "VENDOR_ADMIN_LMENU_MANAGE_SERVICES",
  VENDOR_ADMIN_LMENU_DEPT_USER_MAPPING: "VENDOR_ADMIN_LMENU_DEPT_USER_MAPPING",
  VENDOR_SALES_REPORTS:'VENDOR_SALES_REPORTS',
  VENDOR_WORKFLOW_MANAGE:'VENDOR_WORKFLOW_MANAGE'
};
const PERMISSIONS = {
  DASHBOARD: {},
  BEAT_UPLOAD: {
    BEAT_FILE_UPLOAD: FUNCTIONALITIES.BEAT_FILE_UPLOAD,
    BEAT_UPLOAD_COLLECTOR_WISE: FUNCTIONALITIES.BEAT_UPLOAD_COLLECTOR_WISE,
    ONE_TIME_BEAT_UPLOAD: FUNCTIONALITIES.ONE_TIME_BEAT_UPLOAD,
    FEATURE_BEAT_UPLOAD: FUNCTIONALITIES.FEATURE_BEAT_UPLOAD,
    TODAY_BEAT_REUPLOAD: FUNCTIONALITIES.TODAY_BEAT_REUPLOAD,
  },
  CASH_MEMO: {
    CASH_MEMO: FUNCTIONALITIES.CASH_MEMO,
    HISTORY_CASH_MEMO: FUNCTIONALITIES.HISTORY_CASH_MEMO,
    GODOWN_HISTORY_CASH_MEMO: "GODOWN_HISTORY_CASH_MEMO",
    OPEN_PARTY_HISTORY_CASH_MEMO: "OPEN_PARTY_HISTORY_CASH_MEMO",
  },
  CONFIGURATION: {
    CREATE_COLLECTOR: FUNCTIONALITIES.CREATE_COLLECTOR,
    CREATE_COLLECTOR_CODE: FUNCTIONALITIES.CREATE_COLLECTOR_CODE,
    MAPPING: FUNCTIONALITIES.MAPPING,
    BEAT_FILE_MAPPING: FUNCTIONALITIES.BEAT_FILE_MAPPING,
    USER_HIERARCHY: FUNCTIONALITIES.USER_HIERARCHY,
    INACTIVE_CLIENTS: FUNCTIONALITIES.INACTIVE_CLIENTS,
    ONE_TIME_BEAT_ROUTES: FUNCTIONALITIES.ONE_TIME_BEAT_ROUTES,
    VIEW_GODOWN_MASTER: FUNCTIONALITIES.VIEW_GODOWN_MASTER,
    GODOWN_MASTER_MAPPING: FUNCTIONALITIES.GODOWN_MASTER_MAPPING,
    MANAGE_USERS: FUNCTIONALITIES.MANAGE_USERS,
    MANAGE_DELIVERY_SLOTS: FUNCTIONALITIES.MANAGE_DELIVERY_SLOTS,
    VENDOR_ADMIN_LMENU_MANAGE_USER:
      FUNCTIONALITIES.VENDOR_ADMIN_LMENU_MANAGE_USER,
    VENDOR_ADMIN_BUTTON_MANAGE_USER_ADD:
      FUNCTIONALITIES.VENDOR_ADMIN_BUTTON_MANAGE_USER_ADD,
    VENDOR_ADMIN_LMENU_MANAGE_DELIVERY_SLOT:
      FUNCTIONALITIES.VENDOR_ADMIN_LMENU_MANAGE_DELIVERY_SLOT,
    VENDOR_ADMIN_BUTTON_MANAGE_DELIVERY_SLOT_ADD:
      FUNCTIONALITIES.VENDOR_ADMIN_BUTTON_MANAGE_DELIVERY_SLOT_ADD,
    VENDOR_ADMIN_BUTTON_MANAGE_DELIVERY_SLOT_DEACTIVE:
      FUNCTIONALITIES.VENDOR_ADMIN_BUTTON_MANAGE_DELIVERY_SLOT_DEACTIVE,
    VENDOR_ADMIN_LMENU_ORDER_TIME_CONFIG:
      FUNCTIONALITIES.VENDOR_ADMIN_LMENU_ORDER_TIME_CONFIG,
    VENDOR_LMENU_MANAGE_CATEGORY: FUNCTIONALITIES.VENDOR_LMENU_MANAGE_CATEGORY,
    VENDOR_LMENU_MANAGE_DEPARTMENT:
      FUNCTIONALITIES.VENDOR_LMENU_MANAGE_DEPARTMENT,
    VENDOR_ADMIN_LMENU_MNG_DSHBRD_SECTION:
      FUNCTIONALITIES.VENDOR_ADMIN_LMENU_MNG_DSHBRD_SECTION,
    VENDOR_ADMIN_LMENU_DEPT_USER_MAPPING:
      FUNCTIONALITIES.VENDOR_ADMIN_LMENU_DEPT_USER_MAPPING,
  },
  NOTIFICATION: {
    SEND_PUSH_NOTIFICATION: FUNCTIONALITIES.SEND_PUSH_NOTIFICATION,
  },
  VENDOR_NOTIFICATION: {
    VENDOR_NOTIFICATION_CLIENT: FUNCTIONALITIES.VENDOR_NOTIFICATION_CLIENT,
  },
  ROLES_AND_PERMISSIONS: {
    CREATE_ROLE: FUNCTIONALITIES.CREATE_ROLE,
    ASSIGN_ROLE: FUNCTIONALITIES.ASSIGN_ROLE,
    ROLES_AND_PERMISSIONS: FUNCTIONALITIES.ROLES_AND_PERMISSIONS,
    BUTTON_ASSIGN_ROLE: FUNCTIONALITIES.BUTTON_ASSIGN_ROLE,
  },
  REPORTS: {
    COLLECTION_DATA: FUNCTIONALITIES.COLLECTION_DATA,
    COLLECTION_REPORT: FUNCTIONALITIES.COLLECTION_REPORT,
    COLLECTION_HISTORY: FUNCTIONALITIES.COLLECTION_HISTORY,
    EXCEL_UPLOAD_VIEW: FUNCTIONALITIES.EXCEL_UPLOAD_VIEW,
    COLLECTION_REPORT_BALANCE: FUNCTIONALITIES.COLLECTION_REPORT_BALANCE,
    COLLECTION_REPORT_HISTORY: FUNCTIONALITIES.COLLECTION_REPORT_HISTORY,
    OPEN_PARTY_COLLECTION_REPORT: FUNCTIONALITIES.OPEN_PARTY_COLLECTION_REPORT,
    GODOWN_COLLECTION_REPORT: FUNCTIONALITIES.GODOWN_COLLECTION_REPORT,
    COLLECTION_REPORT_INVOICE: FUNCTIONALITIES.COLLECTION_REPORT_INVOICE,
    MARG_REPORT: FUNCTIONALITIES.MARG_REPORT,
    CASH_FLOW: FUNCTIONALITIES.CASH_FLOW,
  },
  TRACKING: {
    TRACKING: FUNCTIONALITIES.TRACKING,
  },
  WORKFLOW: {
    VIEW_WORKFLOW: FUNCTIONALITIES.VIEW_WORKFLOW,
    CREATE_WORKFLOW: FUNCTIONALITIES.CREATE_WORKFLOW,
    WORKFLOW: FUNCTIONALITIES.WORKFLOW,
  },
  INACTIVE_CLIENTS: {
    VIEW_INACTIVE_CLIENTS: FUNCTIONALITIES.VIEW_INACTIVE_CLIENTS,
  },
  LEAD: {
    ADD_LEAD_TYPE: "ADD_LEAD_TYPE",
  },
  SETTINGS: {
    EXPENSE_TYPE: "EXPENSE_TYPE",
    EXPENSE_CATEGORY: "EXPENSE_CATEGORY",
    VISIT_TYPE: "VISIT_TYPE",
    HOLIDAYS: "HOLIDAYS",
    DEPARTMENT: "DEPARTMENT",
    ADD_BANK: "ADD_BANK",
    DEPARTMENT_MAPPING: "DEPARTMENT_MAPPING",
    VIEW_TERRITORY: "VIEW_TERRITORY",
  },
  GODOWN_UPLOAD: {
    UPLOAD_GODOWN_COLLECTION: FUNCTIONALITIES.UPLOAD_GODOWN_COLLECTION,
  },
  SIFY: {
    SIFY_MASTER_ITEM: FUNCTIONALITIES.SIFY_MASTER_ITEM,
    SIFY_REPORT: FUNCTIONALITIES.SIFY_REPORT,
    SIFY_SALES_DATA: FUNCTIONALITIES.SIFY_SALES_DATA,
    SIFY_PURCHASE_DATA: FUNCTIONALITIES.SIFY_PURCHASE_DATA,
    SIFY_PURCHASE_REPORT: FUNCTIONALITIES.SIFY_PURCHASE_REPORT,
  },
  BANK_DEPOSIT: {
    VIEW_BANK_DEPOSIT: FUNCTIONALITIES.VIEW_BANK_DEPOSIT,
  },
  FEATURE_PRODUCT: {
    PRODUCT_FUNCTIONALITY: FUNCTIONALITIES.PRODUCT_FUNCTIONALITY,
  },
  ORDER_MANAGEMENT: {
    MANAGE_PRODUCT: FUNCTIONALITIES.MANAGE_PRODUCT,
    MANAGE_ORDER: FUNCTIONALITIES.MANAGE_ORDER,
    ORDER_CONFIG: FUNCTIONALITIES.ORDER_CONFIG,
    CUTOFF_TIME: FUNCTIONALITIES.CUTOFF_TIME,
    MANAGE_HOLIDAY: FUNCTIONALITIES.MANAGE_HOLIDAY,
    PURCHASING_PLAN: FUNCTIONALITIES.PURCHASING_PLAN,
    PACKAGING_PLAN: FUNCTIONALITIES.PACKAGING_PLAN,
    VENDOR_ADMIN_LMENU_MANAGE_PRODUCTS:
      FUNCTIONALITIES.VENDOR_ADMIN_LMENU_MANAGE_PRODUCTS,
    VENDOR_ADMIN_LMENU_VIEW_ORDERS:
      FUNCTIONALITIES.VENDOR_ADMIN_LMENU_VIEW_ORDERS,
    VENDOR_ADMIN_LMENU_ORDER_CONFIG:
      FUNCTIONALITIES.VENDOR_ADMIN_LMENU_ORDER_CONFIG,
    VENDOR_ADMIN_LMENU_HOLIDAY_CONFIG:
      FUNCTIONALITIES.VENDOR_ADMIN_LMENU_HOLIDAY_CONFIG,
    VENDOR_ADMIN_LMENU_PURCHASE_PLAN:
      FUNCTIONALITIES.VENDOR_ADMIN_LMENU_PURCHASE_PLAN,
    VENDOR_ADMIN_LMENU_PACKAGE_PLAN:
      FUNCTIONALITIES.VENDOR_ADMIN_LMENU_PACKAGE_PLAN,
    VENDOR_ADMIN_LMENU_MANAGE_SERVICES:
      FUNCTIONALITIES.VENDOR_ADMIN_LMENU_MANAGE_SERVICES,
    VENDOR_SALES_REPORTS:FUNCTIONALITIES.VENDOR_SALES_REPORTS
  },

  COLLECTOR_DASHBOARD: {
    VIEW_COLLECTOR_DASHBOARD: FUNCTIONALITIES.VIEW_COLLECTOR_DASHBOARD,
  },
  OFFERS: {
    VENDOR_ADMIN_LMENU_VENDOR_COUPAN_CONFIG:
      FUNCTIONALITIES.VENDOR_ADMIN_LMENU_VENDOR_COUPAN_CONFIG,
    VENDOR_ADMIN_LMENU_VENDOR_ITEMS_DISCOUNT:
      FUNCTIONALITIES.VENDOR_ADMIN_LMENU_VENDOR_ITEMS_DISCOUNT,
  },
  PARTY_MASTER: {
    VENDOR_LMENU_PARTY_MASTER: FUNCTIONALITIES.VENDOR_LMENU_PARTY_MASTER,
  },
  DISPATCH_MANAGEMENT: {
    VENDOR_ADMIN_LMENU_VIEW_DISPATCH:
      FUNCTIONALITIES.VENDOR_ADMIN_LMENU_VIEW_DISPATCH,
  },
    VENDOR: {
    VENDOR_WORKFLOW_MANAGE: FUNCTIONALITIES.VENDOR_WORKFLOW_MANAGE,
  },
};

const ROLES_PERMISSIONS = {
  PERMISSIONS,
  FEATURES,
};

export default ROLES_PERMISSIONS;
