import React from "react";

import { toast } from "react-toastify";
import FUSIcon from "../FUSIcon/FUSIcon";
import { FUSButton } from "../FUSElements/FUSButton/FUSButton";

const FUSStepperSideBar = ({
  children,
  steps,
  setSteps,
  stepIndex,
  onNextButton,
  onPreviousButton,
}) => {
  return (
    <div
      style={{
        height: "91%",
        paddingTop: 10,
        paddingLeft: 5,
        paddingRight: 5,
      }}
    >
      <div
        className="d-flex justify-content-between "
        style={{
          height:
            steps?.length > 4 &&
            stepIndex !== 0 &&
            steps.findIndex((item) => item.isActive) !== steps.length - 1
              ? "10%"
              : "5%",
        }}
      >
        <div className="row ms-2 me-2 border border-1 border-bottom-1 border-top-0 border-start-0 border-end-0 rounded-0">
          {steps.map((item, index) => {
            if (item?.is_permission_enabled) {
              return (
                <div
                  key={index}
                  className="col-auto"
                  style={{ cursor: "pointer" }}
                >
                  <p
                    className={`mb-0 ${
                      item.isActive
                        ? "border border-success border-2 border-bottom-1 border-top-0 border-start-0 border-end-0 rounded-0 text-success fs-5"
                        : "fs-5"
                    } ${!item.is_permission_enabled ? "text-secondary" : ""}`}
                    onClick={() => {
                      if (item.is_permission_enabled) {
                        setSteps((prev) =>
                          prev.map((itm, idx) => ({
                            ...itm,
                            isActive: idx === index,
                          }))
                        );
                      } else {
                        toast.error(
                          `You don't have permission to access ${item.tab_name}`,
                          { position: "bottom-left", autoClose: 2000 }
                        );
                      }
                    }}
                  >
                    {item.tab_name}
                    <FUSIcon
                      iconSrc={"bi"}
                      iconName={"BiChevronsRight"}
                      size={10}
                      className={"ms-2"}
                    />
                  </p>
                </div>
              );
            }
          })}
        </div>

        <div
          className="me-4 d-flex"
          style={{
            height:
              steps?.length > 4 &&
              stepIndex !== 0 &&
              steps.findIndex((item) => item.isActive) !== steps.length - 1
                ? "50%"
                : "100%",
          }}
        >
          {stepIndex !== 0 && (
            <FUSButton
              onClick={onPreviousButton}
              labelText={"Previous"}
              iconSrc={"bi"}
              iconName={"BiChevronsLeft"}
              size={10}
              className={"btn-sm px-3 py-1 me-2"}
              buttonType="secondary"
              buttonTextStyle
            />
          )}

          {steps.findIndex((item) => item.isActive) !== steps.length - 1 && (
            <FUSButton
              onClick={onNextButton}
              labelText={"Next"}
              iconSrc={"bi"}
              iconName={"BiChevronsRight"}
              size={5}
              className={"btn-sm px-3 py-1"}
              buttonType="primary"
              buttonTextStyle
            />
          )}
        </div>
      </div>
      {steps[stepIndex]?.isActive && <>{children}</>}
    </div>
  );
};

export default FUSStepperSideBar;
